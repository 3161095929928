import { graphql } from "@commerce-frontend/types";
import type { ClientFetcher } from "@labdigital/graphql-fetcher";
import { setPreviewConfigCookie } from "./server";

const StoreLiveEditStory = graphql(/* GraphQL */ `
	mutation StoreContentPreviewData($path: String!, $data: String!) {
		storeContentPreview(data: $data, path: $path) {
			id
		}
	}
`);

// This function is called when the storyblok editor sends an update. The
// update is then sent to the backend, which stores the updated story so it
// can be retrieved later via the preview id
export const storeContentPreviewData =
	(gqlClientFetch: ClientFetcher, cb?: () => void) =>
	async (data: unknown): Promise<void> => {
		// Encode the data since we ran into some issues where the GraphQL gateway
		// failed with a parsing error (invalid JSON was sent)
		// TODO: Investigate this further
		const encoded = Buffer.from(JSON.stringify(data)).toString("base64");

		const previewID = await gqlClientFetch(StoreLiveEditStory, {
			data: encoded,
			path: window.location.pathname,
		}).then((r) => r.data?.storeContentPreview?.id);

		if (previewID) {
			await setPreviewConfigCookie({
				previewID: previewID,
			});
			if (cb) {
				cb();
			}
		}
	};
