import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/frontend/site/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/frontend/site/src/lib/auth/auth-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.19.0_next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.4_5sh53aeuo7ovizvj2vknolqwwu/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._xqsjgrumfhzfigaj5fj2fbsbxa/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/TTCommonsProExp-Rg.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-It.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-Md.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-MdIt.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-DemiBd.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-DemiBd.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/TTCommonsProExp-Bd.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/TTCommonsProExp-BdIt.woff2\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-family\"}],\"variableName\":\"TTCommonsProExp\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._xqsjgrumfhzfigaj5fj2fbsbxa/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family\"}],\"variableName\":\"OpenSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._xqsjgrumfhzfigaj5fj2fbsbxa/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Geologica\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-family-geologica\"}],\"variableName\":\"GeologicaFont\"}");
