"use client";

import { ContentPreviewProvider as ContentPreviewProviderContentful } from "@commerce-frontend/contentful-preview/provider";
import { ContentPreviewProvider as ContentPreviewProviderStoryblok } from "@commerce-frontend/storyblok-preview/provider";
import type { ClientFetcher } from "@labdigital/graphql-fetcher";
import React from "react";

type Props = {
	children: React.ReactNode;
	fetcher: ClientFetcher;
};

const EVOLVE_CMS = process.env.NEXT_PUBLIC_EVOLVE_CMS ?? "contentful";

export const ContentPreviewProvider = ({ children, fetcher }: Props) => {
	switch (EVOLVE_CMS) {
		case "contentful":
			return (
				<ContentPreviewProviderContentful fetcher={fetcher}>
					{children}
				</ContentPreviewProviderContentful>
			);
		case "storyblok":
			return (
				<ContentPreviewProviderStoryblok fetcher={fetcher}>
					{children}
				</ContentPreviewProviderStoryblok>
			);
	}
};
