"use client";
import {
	ContentfulLivePreview,
	type ContentfulSubscribeConfig,
} from "@contentful/live-preview";
import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";

type Props = {
	children: React.ReactNode;
	fetcher: unknown;
};

/**
 * A very simple client side wrapper component needed for registering storyblok.
 *
 * `onUpdate` should be a NextJS server action, that revalidates the current
 * route and updates the cache.
 */
export function ContentPreviewProvider({
	children,
	fetcher,
}: Props): React.ReactElement {
	const [clientSide, setClientSide] = useState(false);
	const router = useRouter();

	useEffect(() => {
		setClientSide(true);

		void ContentfulLivePreview.init({
			locale: "en-GB",
			enableInspectorMode: true,
			enableLiveUpdates: true,
			debugMode: true,
		});

		ContentfulLivePreview.subscribe("save", {
			callback: (data: ContentfulSubscribeConfig["data"]) => {
				router.refresh();
			},
		});
	}, []);

	return <>{children}</>;
}
